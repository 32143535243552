import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const StyledWrap = styled.div`
  margin:64px 0;
  padding:0 16px;
  ${() => media.tablet(css`
    max-width:600px;
    margin:0 auto;
  `)}
  p{
    font-weight:800;
    line-height:1.62em;
  }
`

const BigPhotoTxt = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StaticImage
        src='../../images/soyLong.jpg'
        alt='大豆たち'
        placeholder='none'
      />
      <p>{data.text.focusOnFiber.imgtxt[0]}</p>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "thirtyYearsPage"}) {
      focusOnFiber {
        imgtxt
      }
    }
  }
`

export default BigPhotoTxt
